$(document).on('turbolinks:load', function () {
  const modal_holder_selector = '#modal-holder';
  const modal_selector = '.modal';

  $(document).on('click', 'a[data-modal]', function () {
    const location = $(this).attr('href');
    // Load modal dialog from server
    $.get(location, (data) => {
      $(modal_holder_selector).html(data).find(modal_selector).modal('show');
      flatpickr('.time_picker', {
        enableTime: true,
        noCalendar: true,
        altInput: true,
        dateFormat: 'm/d/Y H:i',
      });
      flatpickr('.only_time_picker', {
        enableTime: true,
        noCalendar: true,
        altInput: true,
        dateFormat: 'H:i K',
      });
      $('.validate').validate();
      $('#sortable').sortable({
        update: function (event, ui) {
          var pos = ui.item.index();
          var url = ui.item.data('sort-url');
          console.log(url, ui);
          $.ajax({
            type: 'POST',
            url: url,
            data: { position: pos },
            success: function (data, textStatus, jqXHR) {},
          });
        },
      });
    });
    $(document).on('hidden.bs.modal', '#mainModal', function (e) {
      $('.modal-backdrop').remove();
    });
    return false;
  });


  $(document).on('click', 'a[data-modal-flat]', function () {
    const location = $(this).attr('href');
    // Load modal dialog from server
    $.get(location, (data) => {
      if(data?.error){
        $('#invalid_case').text(data.error);
        $('#invalid_case').show();
        setTimeout(function() {
          $('#invalid_case').hide();
          window.location.reload();          
        }, 5000);
      }else{
        $(modal_holder_selector).html(data).find(modal_selector).modal('show');
      }  

      $('.decrement').on('click',function(e){
        var dataValue = $(this).closest('.product-quantity-update').data('value');
        var $orderCount = $('#order-count-' + dataValue); 
        var val = $orderCount.val();

        if (val == 1) {
          val=0;
          $('#order-count-' + dataValue).val(val);
          setButtonState($(this), true); // Disable the button and set cursor to not-allowed
        } else if (val > 1) {
          val--;
          $('#order-count-' + dataValue).val(val);
          setButtonState($(this), false); // Enable the button and set cursor to pointer
        }
      });

      $('.increment').on('click',function(){
        const allowMultiple = $(this).closest('.product-quantity-update').data("allow"); // Get the data-allow attribute value
        var dataValue = $(this).closest('.product-quantity-update').data('value');
        var $orderCount = $('#order-count-' + dataValue); 
        var val = $orderCount.val();
        if(val>=1 && allowMultiple){          
          val++;
        }
        if(val==0){
          val=1;
        }
        $('#order-count-' + dataValue).val(val); 
      });

      // Function to manage button state and cursor style
      function setButtonState($button, disable) {
        if(!dataMainValue) $button.css('cursor', disable ? 'not-allowed' : 'default');        
      }
    
    });
    $(document).on('hidden.bs.modal', '#mainModal', function (e) {
      $('.modal-backdrop').remove();
    });
    return false;
  });

  $(document).on('ajax:success', 'form[data-modal]', function (event) {
    const [data, _status, xhr] = event.detail;
    const url = xhr.getResponseHeader('Location');
    if (url) {
      // Redirect to url
      window.location = url;
    } else {
      // Remove old modal backdrop
      $('.modal-backdrop').remove();
      // Update modal content
      const modal = $(data).find('body').html();
      $(modal_holder_selector).html(modal).find(modal_selector).modal();
    }
    $(document).on('hidden.bs.modal', '#mainModal', function (e) {
      $('.modal-backdrop').remove();
    });

    return false;
  });
});
