import interact from "interactjs";
import Swal from 'sweetalert2'
/* The dragging code for '.draggable' from the demo above
 * applies to this demo as well so it doesn't have to be repeated. */
$(document).on("turbolinks:load", function () {
  $(".copyable").on("click", function (event) {
    $(this).off();
    $(this).removeClass("copyable").addClass("table-dragable");
    $(".dragable-panel").append($(this));
    updateTableFloorPosition($(this)[0]);
  });

  interact(".table-dragable")
    .resizable({
      // resize from all edges and corners
      edges: { left: true, right: true, bottom: true, top: true },

      listeners: {
        move(event) {
          var target = event.target;
          var x = parseFloat(target.getAttribute("data-x")) || 0;
          var y = parseFloat(target.getAttribute("data-y")) || 0;

          // update the element's style
          target.style.width = event.rect.width + "px";
          target.style.height = event.rect.height + "px";

          // translate when resizing from top or left edges
          x += event.deltaRect.left;
          y += event.deltaRect.top;

          target.style.webkitTransform = target.style.transform =
            "translate(" + x + "px," + y + "px)";

          target.setAttribute("data-x", x);
          target.setAttribute("data-y", y);
        },
      },
      modifiers: [
        // keep the edges inside the parent
        interact.modifiers.restrictEdges({
          outer: "parent",
        }),

        // minimum size
        interact.modifiers.restrictSize({
          min: { width: 30, height: 30 },
        }),
      ],

      inertia: true,
      onend: dragEndListener,
    })
    .on('doubletap', function (event) {
      var url = event.target.dataset["url"]
      Swal.fire({
        title: 'Are you sure?',
        text: 'This will remove table from floor!',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes, Proceed!',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          window.location = url;
        }
      })
    })
    .draggable({
      listeners: { move: dragMoveListener, end: dragEndListener },
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: "parent",
          endOnly: true,
        }),
      ],
    });

  function dragEndListener(event) {
    updateTableFloorPosition(event.target);
  }
  function dragMoveListener(event) {
    var target = event.target;
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

    // translate the element
    target.style.webkitTransform = target.style.transform =
      "translate(" + x + "px, " + y + "px)";

    // update the posiion attributes
    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);
  }

  // this function is used later in the resizing and gesture demos

  function offset(target) {
    var rect = target.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  function updateTableFloorPosition(target) {
    var table_id = target.dataset.tableId;
    var floorId = target.dataset.floorId;
    var width = parseInt(target.style.width, 10);
    var height = parseInt(target.style.height, 10);
    var position = offset(target);

    var url = "/product_layouts/floors/" + floorId + "/save_table_position";
    $.ajax({
      method: "POST",
      data: {
        table_id: table_id,
        x: position.left - $(".dragable-panel").offset().left,
        y: position.top - $(".dragable-panel").offset().top,
        width: width,
        height: height,
      },
      url: url,
    });
  }
  $(document).on("ajax:success", "form[data-floor]", function (event) {
    const [data, _status, xhr] = event.detail;
    console.log(data);
    if (data.error == "") {
      var persons = "";
      for (let step = 0; step < data.capacity; step++) {
        persons += `<i class="fal fa-male" aria-hidden="true"></i>`;
      }
      var new_table = `<div class="table-dragable table-style ${data.shape} " data-color="${data.color}"
      data-floor-id="${data.floor_id}" data-shape="${data.sahpe}" data-table-id="${data.id}"
      data-url="/product_layouts/floors/${data.floor_id}/remove_table_postion?table_id=${data.id}"
       id="${data.id}" style="position: absolute;  width: 183px; height: 111px; background-color: ${data.color};">
       <div>T-${data.table_no} </div><div>${persons}</div></div>`;
      console.log(new_table);
      $(".dragable-panel").append(new_table);
      $(".modal").modal("hide");
    } else {
      $(".error").html(data.error);
    }

    return false;
  });

  window.dragMoveListener = dragMoveListener;
});
